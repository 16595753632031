import React from 'react';
import {
    Row,
    Col,
} from 'reactstrap';

export interface iProps {
    name : string | JSX.Element,
    address : string | JSX.Element,
    email : string | JSX.Element,
    phone : string | JSX.Element
}

const Heading: React.FC<iProps> = (props: iProps) => {
    return <>
        <Row>
            <Col className="centerText">
                <h1>Joshua Lee Carver</h1>
                <div>9155 Rivers Cove Altamonte Springs Florida 32714</div>
                <div>Joshua@TheCarverCollective.com</div>
                <div>(407) 927-4847</div>
            </Col>
        </Row>
    </>
}

export default Heading;