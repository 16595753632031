const ResumeData = {
  heading : {
    name : "Joshua Lee Carver",
    address : "9155 Rivers Cove Altamonte Springs Florida 32714",
    email : "Joshua@TheCarverCollective.com",
    phone : "(407) 927-4847",
  },
  education : [
    {
      institution : "Seminole State College",
      from : "Aug. 2012",
      to : "May 2016",
      focus : "Software Development AS",
    }, {
      institution : "Lake Brantley High School",
      from : "Aug. 2008",
      to : "May 2012",
      focus : "GED"
    }
  ],
  employer : [
    {
      employer : "AdventHealth",
      role : "Intermediate Software Engineer",
      from : "Nov. 2017",
      to : "Current",
      manager : "Francisco Manalo",
      description : "As a Software Engineer for AdventHealth I have anjoyed the oportunity to work on applications that support our 80,000+ staff accross the organization.  I have worked closely with my team to pioneer new features in Microsoft Sharepoint that garnered the attention and commendation of Microsoft themselves, such as the personalized Employee News Feed and the Connect Mobile Application.  I have also had the oportunity to be a part of and help architect the critically acclaimed and nationally recognized Talent Match Application that is part of our systems response to the Covid-19 Pandemic.  I continue to grow at AdventHealth as a full stack engineer and the leading Reactjs and React-Native engineer for my team.  Please visit my Projects Page to learn more details about my work.",
      collapsed : false,
    }, 
    {
      employer : "AdventHealth",
      role : "PC Techician Associate",
      from : "Nov. 2016",
      to : "Nov. 2017",
      manager : "Manuel Rodriguez",
      description : "As a PC Technician Associate I stepped directly into the role of Asset Management Technician. In this position, I have structured, organized and consolidated processes to create stability and dependability in the Equipment Request System (used to purchase new technical equipment). While taking complete responsibility for these processes, I also prototyped several small tools that assist our department with day to day tasks, most notably Chrome Smart Search.",
      collapsed : true,
    }, 
    {
      employer : "Publix",
      role : "Deli Associate",
      from : "Aug. 2016",
      to : "Oct. 2016",
      manager : "Amanda",
      description : "As a Publix Deli Associate I performed standard kitchen duties while maintaining Cleanliness and Customer Satisfaction expectations. In this position, I operated industrial meat slicing equipment, prepared Subs, and assisted with serving customers at the Hot Bar.",
      collapsed : true,
    }, 
    {
      employer : "Jimmy Johns",
      role : "In-Store",
      from : "Jun. 2016",
      to : "Aug. 2016",
      manager : "Jesse Missingman",
      description : "As an in-store Jimmy Johns Employee, I spent the majority of my time assisting the team with creating Sub Sandwiches at “Freaky Fast” Speeds. In this environment, we worked as a team, communicating and piecing sandwiches together in such large quantities that the Tickets would no longer fit on our Sub Bar Hood. When not on the sandwich line we all shared the duties of maintaining our store and a clean environment.",
      collapsed : true,
    }, 
    {
      employer : "AdventHealth",
      role : "Web Development Intern",
      from : "Feb. 2016",
      to : "Apr. 2016",
      manager : "Vinod Sharma",
      description : "As an AHS Web Team Intern, I assisted with the development of various web based applications (Both WYSIWYG as well as written by hand) while learning about the inner workings of the corporate environment.",
      collapsed : true,
    }, 
    {
      employer : "Kelly Educational Staffing",
      role : "asdf",
      from : "Aug. 2014",
      to : "Apr. 2015",
      manager : "Karen Randolph",
      description : "Substitute Scheduler, Internal IT Support, Digital Design, Web Developer",
      collapsed : true,
    }, 
    {
      employer : "J. Douglas YMCA",
      role : "asdf",
      from : "Jun. 2012",
      to : "Aug. 2014",
      manager : "Kent Mullens",
      description : "Lifeguard, Coach, Swim Instructor",
      collapsed : true,
    },
  ],
  skills : [
    "Fast learner",
    "Team player and collaborator",
    "Responsible for actions taken",
    "Ethical approach",
    "Critical thinker",
    "Patient and resourceful teacher",
    "Natural leader and public speaker",
  ],
  languages : [
    "Typescript",
    "Javascript",
    "HTML5",
    "CSS3 + SASS",
    "Java",
    "JSON",
    ""
  ],
  frameworks : [
    "React + (JSX)",
    "React-Native",
    "Redux",
    "Sharepoint Framework (SPFX)",
    "Bootstrap/Reactstrap Component Library",
    "ElectronJS",
    "PWA (Progressive Web Apps)"
  ],
  misc : [
    "Git Version Control",
    "Node + NPM",
    "RESTful Programming",
    "Agile (Scrum/Kanban) development",
    "Asyncronus Programming",
    "OOP Programming",
    "Functional (Lambda) Programming",
    "Docker",
  ],
  awards : [
    {
      award : 'Eagle Scout',
      icon : ['fas fa-campfire', 'fas fa-hiking', 'fas fa-campground', 'fas fa-compass', 'fas fa-mountains', 'fas fa-first-aid'],
      authority : 'Boy Scouts of America',
    },
    {
      award : 'Order of the Arrow',
      icon : ['fas fa-bow-arrow', 'fas fa-feather-alt'],
      authority : 'Boy Scouts of America',
    },
    {
      award : 'Four Year Varsity Swimmer',
      icon : ['fas fa-swimmer', 'fas fa-swimming-pool'],
      authority : 'Lake Brantley High School',
    },
    {
      award : 'One Year Varsity Water Polo Player',
      icon : ['fas fa-swimming-pool', 'fas fa-swimmer'],
      authority : 'Lake Brantley High School',
    },
    {
      award : 'Programming Competition Awards',
      icon : ['fas fa-laptop-code', 'fas fa-desktop-alt'],
      authority : 'UCF, UF and Stetson',
    },
    {
      award : 'Three Year IBM Master the Mainframe Participant',
      icon : 'fas fa-server',
      authority : 'IBM',
    },
    {
      award : 'Computer Programming Certificate',
      icon : ['fad fa-file-certificate'],
      authority : 'Seminole State College',
    },
    {
      award : 'Computer Programming Specialist Certificate',
      icon : ['fad fa-file-certificate'],
      authority : 'Seminole State College',
    },
    {
      award : 'Information Technology Analysis Certificate',
      icon : ['fad fa-file-certificate'],
      authority : 'Seminole State College',
    },
  ]
}

export default ResumeData;