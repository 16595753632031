import React, { useState, useEffect, useContext } from 'react';
import './SplashStyles.scss';

import { SiteSettingsContext } from './../../context/siteSettings/siteSettings';

const Splash: React.FC = () => {
    const siteSettings = useContext(SiteSettingsContext);

    const [darkness, setDarkness] = useState(0); 
    const [grayscale, setGrayscale] = useState(0);

    useEffect(()=>{
        window.addEventListener('mousemove', (mouseEvent) => {
            const y = mouseEvent.clientY;
            const height = window.innerHeight;
    
            setDarkness((height - y)/height);
            setGrayscale(y/height);
        });
    }, [])
    
    let [str, setStr] = useState('');
    window.addEventListener('mousemove', (mouseEvent)=>{setStr(`y:${mouseEvent.clientY}, height:${window.innerHeight}`)});

    return <>
        <div className="SplashContentContainer">
            <img
                className="SplashImage" 
                style={(siteSettings.enableLandingPageEffects) ? { filter: `brightness(${darkness}) grayscale(${grayscale})`} : {}} 
                src="https://images.unsplash.com/photo-1554291499-563a504e0734?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
                alt="https://unsplash.com/photos/VJTmFSendQ0" />

            <div className="textContainer" style={(siteSettings.enableLandingPageEffects) ? {color: (darkness > .8) ? 'black' : 'white', transition: 'all 1s ease-in'} : {color: 'black'}}>
                <h1 className="dramatic">Welcome to</h1>
                <h3 className="" style={(siteSettings.enableLandingPageEffects) ? {filter: (darkness < .4) ? 'opacity(0)' : '', transition: 'all 1s ease-in'} : {color: 'black'}}>The Carver Collective</h3>
                {/* <h1> {str} : {darkness} {grayscale}</h1> */}
            </div>
        </div>
    </>
}

export default Splash;