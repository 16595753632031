import * as React from 'react';
import {
  Row,
  Col
} from 'reactstrap';

export interface iProps {
  title : string | JSX.Element;
  icon ?: string;
  titleColor ?: '',
  children : JSX.Element | JSX.Element[];
  style?: any;
}

const Title: React.FC<iProps> = (props: iProps) => {
  return <>
    <Row>
      <Col>
        <h2 style={{color : '#ca3e47'}}><span className={props.icon} />{ ` ${props.title}` }</h2>
      </Col>
    </Row>
    <Row style={props.style}>
        { props.children }
    </Row>
  </>
}

export default Title;