import React, { useEffect } from 'react';
import {
    Col
} from 'reactstrap';

export interface iProps {
    award: string;
    icon: string | string[];
    authority: string;
}

const Award: React.FC<iProps> = (props: iProps) => {
  const [iconIndex, setIcon] = React.useState(0);

  const nextIcon = () => {
    if(typeof(props.icon) !== 'string') {
      if(iconIndex < props.icon.length-1) {
        setIcon(iconIndex + 1);
      } else {
        setIcon(0);
      }
    }
  }

  // useEffect(()=>{
  //   if(typeof(props.icon) !== 'string') {
  //     setIcon(Math.floor(Math.random() * props.icon.length));
  //   }
  // }, []);

  return <>
    <div onClick={nextIcon} style={{display : 'flex', width: '10%', justifyContent: 'center', alignItems : 'center', borderRight: 'solid'}}>
      <div style={{ fontSize: '2em', color: 'rgba(0,0,0,.2)'}}><span className={(typeof(props.icon) === 'string') ? props.icon : props.icon[iconIndex]} /></div>
    </div>
    <div onClick={nextIcon} style={{width: '40%', padding: 10}}>
      <div>{props.award}</div>
      <div>{props.authority}</div>
    </div>
  </>
}

export default Award;