import React from 'react';
import {
    Row,
    Col,
    Container
} from 'reactstrap';

import './About.scss';

const About: React.FC = () => {
    return <Container className="AboutContainer">
        <h1>About Joshua Carver</h1>

        <br />

        Add some content to go here that is all about Joshua Carver

        <br /><br />

        Some specific stuff I want to touch on: 
        <ul>
            <li>My Passion for programming and computers</li>
            <li>My dedication to doing what I love, software development, even in my free time</li>
            <li>My Motto</li>
            <li>My Career Goals</li>
            <li>Where I come From:
                <ul>
                    <li>Scouting</li>
                    <li>Swimming</li>
                    <li>Programming Team</li>
                </ul>
            </li>
        </ul>
        </Container>
}

export default About;