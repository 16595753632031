import React from 'react';
import {
    Col
} from 'reactstrap';

export interface iProps {
    institution: string | JSX.Element,
    from: string | Date,
    to: string | Date | 'Current',
    focus: string | JSX.Element,
}

const Education: React.FC<iProps> = (props: iProps) => {
    return <>
        <Col>
            <div>{props.institution}</div>
            <div>{`${props.from} to ${props.to}`}</div>
            <div>{props.focus}</div>
        </Col>
    </>
}

export default Education;