import * as React from 'react';
import { 
    BrowserRouter as Router, 
    Route, 
    Link, 
    Switch
} from "react-router-dom";

import { SiteSettingsContextProvider } from './context/siteSettings/siteSettings';

import './TheCarverCollective.scss';

import Nav from './components/Nav/Nav';
import Splash from './pages/splash/Splash';
import Resume from './pages/resume/Resume';
import Projects from './pages/projects/Projects';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';

interface iProps {
}

const TheCarverCollective: React.FC<iProps> = (props) => {
    return <div>
        <SiteSettingsContextProvider>
            <img className="siteBackground" src="https://images.unsplash.com/photo-1557163123-abbafe113238?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2000&q=80" />
            <Router>
            <Nav />
                <div className="MasterContainer">
                    <Switch>
                        <Route exact path="/" component={Splash} />
                        <Route exact path="/resume" component={Resume}/>
                        <Route exact path="/projects" component={Projects} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/contact" component={Contact} />
                    </Switch>
                </div>
            </Router>
        </SiteSettingsContextProvider>
    </div>;
}

export default TheCarverCollective;