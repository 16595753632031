import React, { useState, useEffect } from 'react';

export interface iSiteSettings {
    enableLandingPageEffects : boolean,
    setState: (newState: iSiteSettings) => void,
}

const defaultSettings: iSiteSettings = {
    enableLandingPageEffects : true,
    setState: (newState: iSiteSettings) => {},
}

export const SiteSettingsContext = React.createContext<iSiteSettings>(defaultSettings);

export const SiteSettingsContextProvider: React.FC = (props) => {

    const [state, stateSetter] = useState(defaultSettings);

    useEffect(()=>{
        var savedSettingsString = window.localStorage.getItem('saved_SiteSettings');
        if(savedSettingsString) {
            var savedSettings: iSiteSettings = JSON.parse(savedSettingsString);
            stateSetter(savedSettings);
        }
    }, [])

    useEffect(()=>{
        window.localStorage.setItem('saved_SiteSettings', JSON.stringify(state));
    }, [state.enableLandingPageEffects])

    return <SiteSettingsContext.Provider value={{...state, setState: stateSetter}}>
        {props.children}
    </SiteSettingsContext.Provider>
}