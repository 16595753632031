import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav as RSNav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';

import './Nav.scss';

import { SiteSettingsContext } from './../../context/siteSettings/siteSettings';

const Nav: React.FC = () => {

    const [installPromptEvent, setInstallPromptEvent] = useState<any>(undefined);
    const [navBarToggle, setNavBarToggle] = useState(false);
    const siteSettings = useContext(SiteSettingsContext);

    useEffect(()=>{ window.addEventListener('beforeinstallprompt', (e)=>{e.preventDefault(); setInstallPromptEvent(e)})}, [])

    return <span className="NavContainer">
        <Navbar color="dark" dark expand="md" style={{zIndex: 100}}>

          <Link to="/" className="navbar-brand">
            <NavbarBrand>
              The Carver Collective
            </NavbarBrand>
          </Link>

          <NavbarToggler onClick={()=>{setNavBarToggle(!navBarToggle)}} />
          <Collapse isOpen={navBarToggle} navbar style={{zIndex: 100}}>
            <RSNav className="ml-auto" navbar>

              <NavItem>
                <Link to="/resume">
                    <NavLink href="/">Resume</NavLink>
                </Link>
              </NavItem>

              <NavItem>
                <Link to="/projects">
                    <NavLink href="/">Projects</NavLink>
                </Link>
              </NavItem>

              {/* <NavItem>
                <Link to="/about">
                    <NavLink href="/">About Joshua</NavLink>
                </Link>
              </NavItem>

              <NavItem>
                <Link to="/contact">
                    <NavLink href="/">Contact</NavLink>
                </Link>
              </NavItem> */}

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Site Options
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={()=>{ (installPromptEvent) ? installPromptEvent.prompt() : console.log('BeforeInstallPrompt Event never fired') }}>
                    Install PWA
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={()=>{siteSettings.setState({...siteSettings, enableLandingPageEffects: !siteSettings.enableLandingPageEffects})}}>
                    { (siteSettings.enableLandingPageEffects)  ? 'Disable Landing Page Effects' : 'Enable Landing Page Effects' }
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </RSNav>
          </Collapse>
        </Navbar>
    </span>
}

export default Nav;