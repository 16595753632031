import React from 'react';
import {
    Row,
    Col,
    Container
} from 'reactstrap';

import './Contact.scss';

const Contact: React.FC = () => {
    return <Container className="ContactContainer">
        <h1>Contact</h1>

        <br />

        Contact Form
    </Container>
}

export default Contact;