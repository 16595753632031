import React from 'react';
import {
    Row,
    Col,
    Container
} from 'reactstrap';

import Title from './../../components/ResumeComponents/Title/Title';

import './Projects.scss';

const Projects: React.FC = () => {

  const maybeTodoList = () => {
    return (window.location.href.indexOf('localhost') > -1) ? <>
      <ul>
        <li>Add GitLab/GitHub links where applicable to public code bases</li>
        <li>Add Store links for mobile apps listed</li>
        <li>Extract Data into component for standardization</li>
        <li>Make Titles Clickable, link should take you to project homepage</li>
      </ul>
      </> : <></>
  }


    return <Container className="ProjectsContainer">
        <h1>Projects</h1>

        <br />

        {maybeTodoList()}

        <Title title="Work Projects">
          <h5>AH Connect Mobile App</h5>
          <div style={{paddingLeft: '2.5%', marginBottom: 25}}>
            <p>Technologies Used: React, React-Native, Typescript, CSS in JS, Redux, Native Platforms (IOS, Android), Git, GitLab</p>
            <p>The AdventHealth Connect app is an Employee Application that allows our users to utilize single sign on to access various company resources such as the company intranet, users paychecks, HR information, and more.  This application serves 80,000+ employees across AdventHealth which spans accross the entire South Eastern quarter of the United States.</p>
            <p>The initial application was developed by a contractor, who met business requirements by creating a series of webviews and embedded various sharepoint pages whithin the app.  When we received the final product in the beginning of 2019, leadership was not happy with the 20+ second wait that users would have to endure for our application pages to load (Due to security steps within the single sign on process);  and this is when we decided to rewrite the majority of the applications UI in React Native to allow for a much better user experience by caching data and moving the authentication process into the background; This solution was discovered and pushed forward by me, even though leadership pushed for about a month, requesting "Make the webview faster".</p>
            <p>Over the next 7 months; I proceeded with rewriting the majority of this application, aided by 1 to 2 contractors at various times; and ultimately handling all aspects of this application; including but not limited to: reveiwing, approving and processing merge requests, configuring test platforms (Testflight and Android Internal testing), creating and submiting production and test builds to their respective store fronts, identifying and fixing various 'showstopper' bugs, rewriting existing features to React-Native, and developing new features requested by Leadership.  While I had a team supporting me; I was the 'point man' for this project and covered all aspects of development over the course of this project, and often advised and educated my senior counterparts as to best practies and what needed to be done to move forward in the project; While I am not an expert, I was and still am the most well versed React and React-Native developer on my team. </p>
          </div>

          <h5>AdventHealth Intranet</h5>
          <p>All Tools: ReactJS, React-Dom, SASS, Typescript, ReactStrap, Microsoft Fabric, Built on Sharepoint Framework Web Apps (SPFX) boiler plate with Sharepoint Lists as the most common 'backend'. </p>
          <div style={{paddingLeft: '2.5%', marginBottom: 25}}>

          <h5>Employee News Feed</h5>
          <div style={{paddingLeft: '2.5%', marginBottom: 25}}>
            <p>The Employee News Feed is a SPFX app that allows individual content publishers within the company to post content to facilities, topics and departments; and users are able to subscribe to these various categories so that their news feed populates with a customized set of posts so that users can stay up to date on company events and more.  This news feed is designed to be a similar experience to the Facebook News Feed with social features such as liking and sharing baked in, and a ranking system based on promoted events and their posted timeframe.</p>
            <p>My experience working on the news feed was not limited to any one aspect.  I worked a lot on making the feed and individual card layouts look like their mockup counterparts; as well as integrating some more advanced React features and patterns to try and create a consistant and clean development environment while trying to eliminate duplicate code. </p>
          </div>

          <h5>Feed Publishing</h5>
          <div style={{paddingLeft: '2.5%', marginBottom: 25}}>
            <p>The Publishing app is ultimately a companion SPFX application for the Employee News Feed.  This app is how publishers are able to create new posts.  Feed Publishing is a branching form that allow the users to enter data into controlled fields and select options pertaining to their post; once this data has been collected, the application writes the users data to a sharepoint list via restful api's for consumption by the News Feed App. </p>
          </div>

          <h5>Mission Integration Peer Review</h5>
          <div style={{paddingLeft: '2.5%', marginBottom: 25}}>
            <p>The MIPR app is a tool used to collect survey data and generate organized reports.  This application has two primary parts, first is a list of upcomming facility reviews; this list is powered by a sharepoint list behind the scenes and displays pertinant information regarding the facilities review date, important leadership figures, a photo of the facility, and a button to access that facilities review documentation and submission page.</p>
            <p>The individual review page is the second part of the MIPR app.  This secondary page displays more details pertaining to the site visit such as the visit schedule and meeting locations, the surveyors participating in the Mission Integration Peer Review that day, the facilities address and additional details, and the leadership team of that facility. This page also has sharepoint document libraries embedded in the page so that surveyors can access additional review materials as well as a location to upload their findings. </p>
          </div>

          <h5>Talent Match</h5>
          <div style={{paddingLeft: '2.5%', marginBottom: 25}}>
            <p>The Talent Match application has been one of AdventHealth's responses to the Covid-19 Pandemic.  This applications purpose is to allow our Organization to easily relocate our employees where they may be needed either because of high demand or that employees practice being temporarily inturrupeted; it was AdventHealth's mission to avoid layoffs and 'Redeploy' staff to minimize the burden of cost while keeping staff employed at their normal hours and rates.</p>
            <p>As an engineer with a background of working at Kelly Educational Staffing and helping place substitutes in classrooms; I assisted with the archectecture and planning of the Talent Match application as well as helping identify pitfalls in various approaches.  Working on this application allowed me to grow as a software architect and help create an application that helped keep our staff effective through unstable times.</p>
            <p>The Talent Match Application functioned on many levels and became a very complex application with multi-fauceted approaches to redeploying staff.  
              <ol>
                <li>
                  The process of redeployment would start with the Team Member electing to be redeployed through a "Raising of the Hand" process, or the Team Members leadership identified them for redeployment.  Either way the employee was given a profile they could manage and were screened for eligibility and willingness to be redeployed.  Once the Team Member was processed they would appear as an eligible Team Member inside the Redeployment Application.
                </li>
                <li>
                  Asyncronously, Leaders at AdventHealth are able to determine a "Need" within their department where they can use some assistance.  The Leader is then able to navigate to the Talent Match Portal and register this need in the form of a Job Posting.  It is here that the Leader is responsible identifying the role needs and requirments such as the Who, What, When and Where of the role.
                </li>
                <li>
                  Finally, where the magic happens within the Talent Match application.  A dedicated group of Team Members utilizes the HR portion of the Talent Match software to review a list of eligible Team Members and a list of Needs posted by Leaders; and it is up to that individual identify matches and place Team Members in applicable roles.  Matching is aided by generating match compatibility ratings and determining if the Team Member is even eligible for the role; this complex sorting and filtering of Team Members and Roles greatly aids the Redeployment process and allows our team effectively and efficiently relocate our Team Members for the benefit of all. 
                </li>
              </ol>
            </p>
            <ul>
              <li>
                <a target="_blank" href="https://www.youtube.com/watch?v=XIAImX2A7b4">CEO Terry Shaw addresses Covid-19 on Face The Nation</a>
              </li>
              <li>
                <a target="_blank" href="https://www.advisory.com/research/hr-advancement-center/studies/2020/how-adventhealth-quickly-redeployed-staff-during-covid-19">How AdventHealth quickly redeployed staff during Covid-19</a>
              </li>
            </ul>
          </div>

          </div>
        </Title>

        <Title title="Personal Projects">
          <h5>The Carver Collective</h5>
          <div style={{paddingLeft: '5%', marginBottom: 25}}>
          <p>Tools: ReactJS, React-Dom, SASS, Typescript, ReactStrap, Google Firebase, NodeJS, etc...</p>
            <p>The Carver Collective is the site you are currently viewing; and it is ultimately my sandbox (Pardon any stray sand castles on toothpick stilts, I'm always trying new things in the sandbox).  The Carver Collective is in practice my portfolio website and is always the first site to be upgraded when I take on a new stack or have a bold new idea for design or features.  This site is also a Progressive Web App, which has been my first real world attempt at this practice and I look forward to enhancing the App like features that this site can have. </p>
          </div>

          <h5>Carvers Captures</h5>
          <div style={{paddingLeft: '5%', marginBottom: 25}}>
          <p>Tools: PHP, HTML5, CSS3, SQL, Hosted on a Shared Virtual Server</p>
            <p>Carvers Captures has not been updated in quite a while, but still stands as my photography website.  Though it is slated for a refresh, you can still visit the site today and view subtle animations as well as some facebook integrations that I messed around with; all this was done at the end of my highschool career and beginning of college; I've come a long way and can't wait to see what the next iteration of Carvers Captures looks like. </p>
          </div>
        </Title>


        {/* <h4>FreeLance/Contract Projects</h4>
        <ul>
            <li>The Traveling Artist Collective</li>
            <li>Classy Kobolds</li>
        </ul> */}
    </Container>
}

export default Projects;