import * as React from 'react';
import {
    Row, 
    Col,
    Collapse
} from 'reactstrap';

export interface iProps {
    employer : string | JSX.Element,
    role : string | JSX.Element,
    from : string | Date,
    to : string | Date | 'Current',
    manager : string | JSX.Element,
    description : string | JSX.Element,
    collapsed : boolean,
}

const Employer: React.FC<iProps> = (props: iProps) => {
  const [collapsedState, setCollapsedState ] = React.useState(props.collapsed);

  const icon = {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
  }

  const sizing = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 3,
    xl: 3,
  }

  const sizingMain = {
    xs: 11,
    sm: 11,
    md: 11,
    lg: 5,
    xl: 5,
  }

  return <>
    <Row style={{marginTop: 15, padding: 10, backgroundColor: "rgba(92, 98, 98, .75)", color: 'white', borderRadius: 10, cursor: 'pointer'}} onClick={()=>{setCollapsedState(!collapsedState)}}>
      <Col {...icon} style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}><span style={{ transition: 'all .5s ease-in-out', transform : (!collapsedState) ? 'rotate(90deg)' : ''}} className={'fas fa-chevron-right'} /></Col>
      <Col {...sizingMain}>{props.employer} <br /> {props.role}</Col>
      <Col {...sizing} style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>{props.from} to {props.to}</Col>
      <Col {...sizing} style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>{props.manager}</Col>
    </Row>
    <Collapse isOpen={!collapsedState}>
      <Row>
        <Col style={{marginLeft: "5%", marginRight: "5%"}}>{props.description}</Col>
      </Row>
    </Collapse>
  </>

  return <>
  <Row style={{marginTop: 15, marginBottom: 15}} onClick={()=>{setCollapsedState(!collapsedState)}} >
      <Col>
          <span style={{fontWeight: 'bold'}}>{props.employer} : {props.role}</span>
          { !collapsedState && <div>{props.from} to {props.to}</div> }
          { !collapsedState && <div>{props.manager}</div> }
      </Col>
      { !collapsedState && <Col>{props.description}</Col> }
  </Row>
  </>
}

export default Employer;