import React from 'react';
import {
    Row,
    Col,
    Container
} from 'reactstrap';
import Title from './../../components/ResumeComponents/Title/Title';

import './Resume.scss';
import ResumeData from './ResumeData';

import Heading from './../../components/ResumeComponents/Heading/Heading';
import Education from './../../components/ResumeComponents/Education/Education';
import Employer from './../../components/ResumeComponents/Employer/Employer';
import Award from './../../components/ResumeComponents/Award/Award';

const Resume: React.FC = () => {
    return <>
    <Container className="ResumeContainer">
        <Heading {...ResumeData.heading} />

        <Row style={{marginTop: 50, marginBottom: 50}}>
          <Col>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{width: "100%", textAlign: 'center'}}>
                As an enthusiastic and experienced software engineer I am dedicated to excelling as a full stack developer and creating cross platform software.  I accomplish this by using strictly typed web apps written in Typescript and React to ensure stability, efficiency and scalability while serving these apps on various platforms using React-Native, ElectronJS, Progressive Web Apps or good ‘ol HTTPS.  Whenever I encounter a new challenge such as a new library, platform, framework, language, etc; I pride myself in the fact that I take the initiative to fully comprehend and then conquer the challenge in question.
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{textAlign: "center"}}>
          <Col>
            <Title icon="fab fa-react" title="Frameworks">
              <Col>
              { ResumeData.frameworks.map(skill => <Row style={{justifyContent: "center"}}>{skill}</Row>) }
              </Col>
            </Title>
          </Col>

          <Col>
            <Title icon="fab fa-js-square" title="Languages">
              <hr style={{}} />
              <Col>
              { ResumeData.languages.map(skill => <Row style={{justifyContent: "center"}}>{skill}</Row>) }
              </Col>
            </Title>
          </Col>
        </Row>

        <Row style={{textAlign: "center"}}>
          <Col>
            <Title icon="fas fa-chalkboard-teacher" title="Skills">
              <Col>
                { ResumeData.skills.map(skill => <Row style={{justifyContent: "center"}}>{skill}</Row>) }
              </Col>
            </Title>
          </Col>

          <Col>
            <Title icon="fab fa-git-alt" title="Misc.">
              <Col>
                { ResumeData.misc.map(skill => <Row style={{justifyContent: "center"}}>{skill}</Row>) }
              </Col>
            </Title>
          </Col>
        </Row>

        <Title icon='fas fa-file-certificate' title="Awards and Achievements" >
            { ResumeData.awards.map(a => <Award {...a} />)}
        </Title>
        
        <Title icon='fas fa-chart-network' title="Employment">
          <Col>
            { ResumeData.employer.map( employer => <Employer {...employer} />) }
          </Col>
        </Title>



        <Title icon='fas fa-school' title="Education">
            <Education {...ResumeData.education[0]} />
            <Education {...ResumeData.education[1]} />
        </Title>

    </Container>
    </>
}

export default Resume;